import { render, staticRenderFns } from "./comp_ProductInfoDescription.vue?vue&type=template&id=2c9ccbea&scoped=true"
import script from "./comp_ProductInfoDescription.vue?vue&type=script&lang=js"
export * from "./comp_ProductInfoDescription.vue?vue&type=script&lang=js"
import style0 from "./comp_ProductInfoDescription.vue?vue&type=style&index=0&id=2c9ccbea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9ccbea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VRow})
