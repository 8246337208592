import { render, staticRenderFns } from "./comp_ShopApp.vue?vue&type=template&id=5d30e2e2"
import script from "./comp_ShopApp.vue?vue&type=script&lang=js"
export * from "./comp_ShopApp.vue?vue&type=script&lang=js"
import style0 from "./comp_ShopApp.vue?vue&type=style&index=0&id=5d30e2e2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
