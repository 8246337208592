import { render, staticRenderFns } from "./unused_comp_ProductPage-Attributes.vue?vue&type=template&id=3e2767e6"
import script from "./unused_comp_ProductPage-Attributes.vue?vue&type=script&lang=js"
export * from "./unused_comp_ProductPage-Attributes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
